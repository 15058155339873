<template>
  <router-view />
</template>

<script setup>
import { onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'

import i18n from '@/config/i18n'
import { request } from '@/request/request'
import { useAuthorizationStore } from '@/stores/views/authorization.store'

const router = useRouter()

const authorizationStore = useAuthorizationStore()

const storedCredentials = localStorage.getItem('tokens')
const storedLocale = localStorage.getItem('locale')

if (storedLocale) {
  request.setAcceptLanguage(storedLocale)
}

if (storedCredentials) {
  request.setAuthToken(JSON.parse(storedCredentials).accessToken)
} else {
  useRouter().push({ name: 'Login' })
}

onMounted(async () => {
  if (storedCredentials) {
    await authorizationStore.refreshToken(JSON.parse(storedCredentials))
  } else {
    await authorizationStore.getUser()
  }
})

watch(i18n.global.locale, (newLocale) => {
  localStorage.setItem('locale', newLocale)
  request.setAcceptLanguage(newLocale)

  router.go()
})
</script>

<style lang="scss">
@import 'assets/style/general';
</style>
