import '@rhsolutions/design-system/dist/assets/index.css'

import { createPinia } from 'pinia'
import { createApp } from 'vue'

import i18n from '@/config/i18n'
import { request } from '@/request/request'

import App from './App.vue'
import router from './router'

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)
app.use(i18n)
app.use(router)

request.init()

app.mount('#app')
